import { Pagination } from '@eagle/api-types';
import { Feature, Thing, ThingEventSnapshot } from '@eagle/core-data-types';
import { FeatureTypes, locationRelatedEventTypes } from '@eagle/data-function-types';
import { Alert, AppliedFilter, CacheDataTypes, EVENT_DATA_MAX_DAYS, EventsTable, FeatureWithInstancesAndEvents, FILTER_SELECT_ALL_FLAG, FindItemsDeferredPaginatedResponse, getEventLabel, MiddleSpinner, Query, SelectionCheckBox, SelectionCheckBoxGroup, useAuthenticated, useBoolFlag, useFetchAllCache, useMyNormalizedFeaturesQuery, useNumberFlag, usePromise, useSmallScreen } from '@eagle/react-common';
import { Cancel } from '@mui/icons-material';
import { Card, CardContent, Chip, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import Axios from 'axios';
import { DateTime } from 'luxon';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EVENT_DATA_MAX_DAYS_FLAG } from '../thing-detail';
import { ThingEventBase } from './thing-event-base';
import { ThingEventInvestigateButton } from './thing-event-investigate-button';
import { getFeatureTranslation, simplifyThingEventFilter, ThingEventFilter } from './thing-event.utils';

export const DataDrivenThingEventsV2: FC<{ thing: Thing }> = ({ thing }) => {
  const { restClient } = useAuthenticated();
  const [activeFilters, setActiveFilters] = useState<ThingEventFilter[]>([]);
  const { t } = useTranslation(['common']);
  const hasTrackThingHistoryFlag = useBoolFlag('track-thing-history-feature');
  const maxDays = useNumberFlag(EVENT_DATA_MAX_DAYS_FLAG) || EVENT_DATA_MAX_DAYS;
  const thingFeaturesQuery = useMyNormalizedFeaturesQuery(thing._id);

  const featureCache = useFetchAllCache(CacheDataTypes.FEATURE);
  const [features, featuresError, featureState] = usePromise<Feature[]>(() => featureCache.all(), [featureCache]);

  const handleQueryChanged = useCallback(({ filters }: Query, pagination: Pagination): FindItemsDeferredPaginatedResponse<ThingEventSnapshot> => {
    const cancelToken = Axios.CancelToken.source();
    return {
      cancel: () => cancelToken.cancel(),
      promise: restClient.thingEvent.getWithThing(thing._id, {
        dateRangeStart: DateTime.now().minus({ days: maxDays }).toUTC().toJSDate(),
        filter: { '$or': simplifyThingEventFilter(filters as unknown as ThingEventFilter[]) },
        limit: 5,
        skip: pagination.skip,
        sort: JSON.stringify({ occurred: -1 }),
      }, { cancelToken: cancelToken.token }),
    };
  }, [restClient, thing._id, maxDays]);

  const hasError = thingFeaturesQuery.status === 'error' || featuresError;

  if (thingFeaturesQuery.status === 'pending' || featureState === 'pending') {
    return (
      <Card>
        <CardContent>
          <MiddleSpinner />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ '& .MuiCardContent-root': { '&:last-child': { py: 0 }, p: 0 }, p: 0 }}>
      <CardContent>
        <ThingEventBase
          isFiltering={activeFilters.length > 0}
          headerContent={thingFeaturesQuery.status === 'success' ?
            <DataDrivenThingEventFilter
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              thingFeatures={thingFeaturesQuery.data}
              features={features ?? []}
            />
            : null
          }
          maxDays={maxDays}
        >
          {hasError ? (
            <Alert sx={{ m: 2 }} severity='error'>{t('common.hint.error')}</Alert>
          ) : (
            <EventsTable
              appliedFilters={activeFilters as unknown as AppliedFilter[]}
              fixHeight={true}
              hideCategory
              onQueryChanged={handleQueryChanged}
              rowChildren={hasTrackThingHistoryFlag ? (
                (event) => (
                  <ThingEventInvestigateButton
                    event={event}
                  />
                )
              ) : undefined}
              thing={thing}
            />
          )}
        </ThingEventBase>
      </CardContent>
    </Card>
  );
};

const MAX_NUMBER_OF_CHIPS = 3;

interface DataDrivenThingEventFilterProps {
  activeFilters: ThingEventFilter[];
  setActiveFilters: Dispatch<SetStateAction<ThingEventFilter[]>>;
  thingFeatures: FeatureWithInstancesAndEvents[];
  features: Feature[];
}

const DataDrivenThingEventFilter: FC<DataDrivenThingEventFilterProps> = ({ activeFilters, setActiveFilters, thingFeatures, features }) => {
  const smallScreen = useSmallScreen();
  const { t } = useTranslation(['common', 'track']);
  const hasFilterSelectAllFlag = useBoolFlag(FILTER_SELECT_ALL_FLAG);

  const options = useMemo(() => {
    const output = [];

    for (const feature of thingFeatures) {
      const featureId = feature.feature;
      const featureTypeId = features.find((feature) => feature._id === featureId)?.featureTypeId;
      if (!featureTypeId) {
        continue;
      }
      const events = feature.events
        .filter((event) => !locationRelatedEventTypes.includes(event))
        .sort((a, b) => {
          return getEventLabel(feature.feature, a).localeCompare(getEventLabel(feature.feature, b));
        });

      if (events.length === 0) continue;

      if (feature.instances.length === 0) {
        output.push({ feature: feature.feature, featureTypeId, events, label: t(`common:features.${featureId}`) });
      }

      else {
        for (const instance of feature.instances) {
          const featureWithInstance = `${featureId}/${instance}`;
          output.push({ feature: featureWithInstance, featureTypeId, events, label: getFeatureTranslation(featureWithInstance) });
        }
      }
    }

    return output.sort((a, b) => a.label.localeCompare(b.label));
  }, [thingFeatures, features, t]);

  const getSelectedLabel = (filter: ThingEventFilter): string => {
    const featureLabel = getFeatureTranslation(filter.feature);
    const eventLabel = getEventLabel(filter.feature, filter.eventTypeId);

    return filter.featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? `${featureLabel} – ${eventLabel}` : `${eventLabel} – ${featureLabel}`;
  };

  const numberOfOptions = options.reduce((result, option) => result + option.events.length, 0);
  const isAllSelected = activeFilters.length === numberOfOptions;

  const toggleSelectAll = (): void => {
    if (isAllSelected) {
      setActiveFilters([]);
      return;
    }
    const allValues = options.flatMap((option) => option.events.map((event) => ({
      feature: option.feature,
      eventTypeId: event,
      featureTypeId: option.featureTypeId,
    })));
    setActiveFilters(allValues);
  };

  return (
    <FormControl size='small'>
      {activeFilters.length === 0 && <InputLabel shrink={false}>{t('common:component.events.labels.event-filter')}</InputLabel>}
      <Select
        data-testid="event-filter"
        fullWidth
        multiple
        MenuProps={{
          sx: {
            maxWidth: 350,
            maxHeight: 400,
            '& .MuiMenu-paper': {
              width: '100%',
            },
          },
          onClick: (e) => { e.stopPropagation(); },
        }}
        renderValue={(values) => (
          <Stack spacing={1} sx={{ alignItems: 'flex-start' }}>
            {values.slice(0, MAX_NUMBER_OF_CHIPS).map((value, i) => (
              <Chip
                key={i}
                size="small"
                onDelete={() => {
                  setActiveFilters((selected) => {
                    const newValue = selected.filter(({ feature, eventTypeId }) => !(feature === value.feature && eventTypeId === value.eventTypeId));
                    return newValue;
                  });
                }}
                sx={{ maxWidth: '100%' }}
                deleteIcon={<Cancel onMouseDown={(e) => { e.stopPropagation(); }} />}
                label={getSelectedLabel(value)}
              />
            ))}
            {values.length > MAX_NUMBER_OF_CHIPS && <Typography component="span" variant="body2">+{values.length - MAX_NUMBER_OF_CHIPS}</Typography>}
          </Stack>
        )}
        sx={{ width: smallScreen ? '100%' : 250 }}
        value={activeFilters}
      >
        {hasFilterSelectAllFlag && (
          <MenuItem divider sx={{ p: 0 }}>
            <SelectionCheckBox
              checked={isAllSelected}
              handleClick={toggleSelectAll}
              label={<strong>{t('common:component.filter-dropdown.labels.select-all')}</strong>}
            />
          </MenuItem>
        )}
        {options.map((option) => (
          <SelectionCheckBoxGroup
            key={option.feature}
            itemsChecked={activeFilters.filter(({ feature }) => feature === option.feature)?.map(({ eventTypeId }) => eventTypeId)}
            groupDisplay={option.label}
            subItemDisplay={(subItem) => getEventLabel(option.feature, subItem)}
            subItems={option.events}
            updateSelectedValue={(eventTypeIds) => {
              setActiveFilters((selected) => {
                const newValue = selected.filter(({ feature }) => feature !== option.feature);
                newValue.push(...eventTypeIds.map((eventTypeId) => ({ feature: option.feature, eventTypeId, featureTypeId: option.featureTypeId })));
                return newValue;
              });
            }}
            wrap
          />
        ))}
      </Select>
    </FormControl>
  );
};
